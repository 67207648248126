/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Argon Dashboard 2 PRO MUI are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 PRO MUI layouts
import Landing from "layouts/dashboards/landing";
import Default from "layouts/dashboards/default";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/pages/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import LatestResearch from "layouts/ecommerce/products/Latest Research";
import LatestEvents from "layouts/ecommerce/products/Latest Events";

import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpBasic from "layouts/authentication/sign-up/basic";
import SignUpCover from "layouts/authentication/sign-up/cover";
import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetBasic from "layouts/authentication/reset-password/basic";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

const routes = [
  {
    type: "collapse",
    name: "News and Updates",
    key: "news-and-updates",
    icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-archive-2" />,
    collapse: [
      {
        name: "Events",
        key: "events-and-conference-updates",
        route: "/news-and-updates/events-and-conference-updates",
        component: <LatestEvents />,
      },
      {
        name: "Latest Researches",
        key: "latest-research-findings",
        route: "/news-and-updates/latest-research-findings",
        component: <LatestResearch />,
        // collapse: [
        //   {
        //     name: "Research List",
        //     key: "research-list",
        //     route: "/news-and-updates/latest-research-findings",
        //     component: <LatestResearch />,
        //   },
        //   // {
        //   //   name: "Add Research",
        //   //   key: "add-research",
        //   //   route: "/news-and-updates/add-research",
        //   //   component: <EditProduct />,
        //   // },
        // ],
        // component: <LatestResearch />,
      },

      // {
      //   name: "Products",
      //   key: "products",
      //   collapse: [
      //     // {
      //     //   name: "New Product",
      //     //   key: "new-product",
      //     //   route: "/ecommerce/products/new-product",
      //     //   component: <NewProduct />,
      //     // },
      //     {
      //       name: "Edit Product",
      //       key: "edit-product",
      //       route: "/ecommerce/products/edit-product",
      //       component: <EditProduct />,
      //     },
      //     {
      //       name: "Product Page",
      //       key: "product-page",
      //       route: "/ecommerce/products/product-page",
      //       component: <ProductPage />,
      //     },
      //     {
      //       name: "Products List",
      //       key: "products-list",
      //       route: "/ecommerce/products/products-list",
      //       component: <ProductsList />,
      //     },
      //   ],
      // },
      // {
      //   name: "Orders",
      //   key: "orders",
      //   collapse: [
      //     {
      //       name: "Order List",
      //       key: "order-list",
      //       route: "/ecommerce/orders/order-list",
      //       component: <OrderList />,
      //     },
      //     {
      //       name: "Order Details",
      //       key: "order-details",
      //       route: "/ecommerce/orders/order-details",
      //       component: <OrderDetails />,
      //     },
      //   ],
      // },
      // {
      //   name: "Referral",
      //   key: "referral",
      //   route: "/ecommerce/referral",
      //   component: <Referral />,
      // },
    ],
  },
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <ArgonBox component="i" color="error" fontSize="14px" className="ni ni-single-copy-04" />,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-in/basic",
  //           component: <SignInBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-in/cover",
  //           component: <SignInCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-in/illustration",
  //           component: <SignInIllustration />,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default routes;
