/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonEditor from "components/ArgonEditor";
import ArgonSelect from "components/ArgonSelect";
import ArgonButton from "components/ArgonButton";
import { Link } from "react-router-dom";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-research/components/FormField";
import ArgonDatePicker from "components/ArgonDatePicker";
import { Box, Typography } from "@mui/material";
import Media from "../Media";
function ProductInfo() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSetStartDate = (newDate) => setStartDate(newDate);
  const handleSetEndDate = (newDate) => setEndDate(newDate);
  return (
    <Card sx={{ overflow: "visible" }}>
      <ArgonBox p={3}>
        <ArgonTypography variant="h5">Event Information</ArgonTypography>
        <ArgonBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="Title" defaultValue="Global Warming Conference" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Date</Typography>
              <ArgonDatePicker value={startDate} onChange={handleSetStartDate} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField type="text" label="Location" defaultValue="Bole, Dembel" />
            </Grid>
          </Grid>
        </ArgonBox>

        <ArgonBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <ArgonTypography component="label" variant="caption" fontWeight="bold">
                  Description&nbsp;&nbsp;
                  <ArgonTypography variant="caption" fontWeight="regular" color="text">
                    (optional)
                  </ArgonTypography>
                </ArgonTypography>
              </ArgonBox>
              <ArgonEditor />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Media />
            </Grid> */}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Link to="/news-and-updates/events-and-conference-updates">
              <ArgonButton variant="gradient" color="info" size="small">
                Save
              </ArgonButton>
            </Link>
          </Box>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ProductInfo;
