/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import { Link } from "react-router-dom";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DefaultBlogCard from "examples/Cards/BlogCards/DefaultBlogCard";
// NewProduct page components
import Header from "layouts/ecommerce/products/new-product/components/Header";
import { Typography, Box, Button } from "@mui/material";
import ArgonTypography from "components/ArgonTypography";

// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function getSteps() {
  return ["1. Product Info", "2. Media", "3. Social", "4. Pricing"];
}

function LatestResearch() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      <Header />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 10 }}>
        <Link to="/news-and-updates/add-research">
          <ArgonButton variant="gradient" color="info" size="small">
            + New Research
          </ArgonButton>
        </Link>
      </Box>
      <Grid container mt={2} columnSpacing={3} rowSpacing={3} sx={{ width: "100%" }} ml={1}>
        {Array(8)
          .fill("")
          .map((data, index) => {
            return (
              <Grid xs={12} md={4} key={index} sx={{ my: 2 }}>
                <Card sx={{ px: 2, py: 2, width: "95%" }}>
                  <img
                    src={
                      index % 2 === 0
                        ? "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg"
                        : "https://cdn.downtoearth.org.in/library/large/2023-01-06/0.74726000_1673000613_istock-1001629060.jpg"
                    }
                    alt="no image"
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <ArgonTypography
                      display="inline"
                      variant="h5"
                      textTransform="capitalize"
                      className="color-background"
                      sx={{ my: 1 }}
                    >
                      Title
                    </ArgonTypography>
                    <Typography variant="body2">2 mins read</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                  >
                    <Link to="/news-and-updates/edit-research">
                      <ArgonButton variant="gradient" color="primary">
                        Edit
                      </ArgonButton>
                    </Link>
                    <ArgonButton variant="gradient" color="error">
                      Delete
                    </ArgonButton>
                  </Box>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default LatestResearch;
